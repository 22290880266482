import React, { useState, useEffect, useCallback } from 'react'
import qs from 'qs'
import axios from 'axios'
import PropTypes from 'prop-types'
import { deserialize } from 'deserialize-json-api'
import { get, round, replace, isUndefined } from 'lodash'

import SEO from '../../atoms/Seo'
import JsonLD from '../../atoms/JsonLD'
import Navbar from '../../organisms/Navbar'
import CityPrograms from '../../organisms/CityPrograms'
import CityTopSection from '../../organisms/CityTopSection'
import { API_SALES_URL } from '../../../config'

const RegionTemplate = ({ pageContext, location }) => {
  const [loading, updateLoading] = useState(true)
  const [programs, updatePrograms] = useState([])
  const [total, updateTotal] = useState(0)

  const id = get(pageContext, 'id', '')
  const region = get(pageContext, 'name', '')
  const search = qs.parse(replace(get(location, 'search'), '?', ''))
  const page = get(search, 'page')
  const [currentPage, updatePage] = useState(isUndefined(page) ? 1 : parseInt(page))
  const title = `Programme Neuf - ${region}`
  const description = `Tous les appartements neufs à vendre à ${region} du 2 au 5 pièces. Prix direct Promoteur. Terrasse, balcon, parking.`

  const linkedData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: 'Folhomee',
    description,
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    keywords: 'Programme immobilier, Folhomee, définition nue propriété, accompagnement'
  }

  const fetchCount = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/programs/count_search`, {
        params: {
          regions: [id],
          lotType: get(search, 'nature'),
          minRooms: get(search, 'minRooms'),
          maxRooms: get(search, 'maxRooms'),
          maxPrice: get(search, 'budget'),
          minSurface: get(search, 'surface'),
          deliveryYear: get(search, 'deliveryYear'),
          deliveryQuarter: get(search, 'deliveryQuarter')
        }
      })

      updateTotal(get(data, 'programs', 0))
    } catch (err) {
      console.log(err)
    }
  }, [search])

  const fetchPrograms = useCallback(async () => {
    updateLoading(true)

    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/programs/search`, {
        params: {
          regions: [id],
          lotType: get(search, 'nature'),
          minRooms: get(search, 'minRooms'),
          maxRooms: get(search, 'maxRooms'),
          maxPrice: get(search, 'budget'),
          minSurface: get(search, 'surface'),
          deliveryYear: get(search, 'deliveryYear'),
          deliveryQuarter: get(search, 'deliveryQuarter'),
          'page[number]': currentPage,
          'page[size]': 15
        }
      })

      updatePrograms(get(deserialize(data), 'data'))
    } catch (err) {
      console.log(err)
    } finally {
      updateLoading(false)
    }
  }, [search, currentPage])

  useEffect(() => {
    fetchPrograms()
  }, [currentPage])

  useEffect(() => {
    fetchCount()
    fetchPrograms()
  }, [location])

  return (
    <>
      <SEO
        title={title}
        location={location}
        description={description}>
        <JsonLD>
          {linkedData}
        </JsonLD>
      </SEO>
      <Navbar location={location} />
      <CityTopSection
        loading={loading}
        location={{
          type: 'region',
          region: [{
            id,
            name: region
          }],
          breadcrumb: {
            region
          }
        }}
        programs={programs} />
      <section
        id={`${region} liste des programmes neufs`}>
        <CityPrograms
          city={region}
          page={currentPage}
          loading={loading}
          maxPage={round(total / 15)}
          programs={programs}
          updatePage={updatePage} />
      </section>
    </>
  )
}

RegionTemplate.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object.isRequired
}

export default RegionTemplate
